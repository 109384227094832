<template>
  <div class="flex auth-page">
    <div>
      <div class="space">
        <div>
          <router-link
            :to="{ name: 'Landing', query: { to: $route.query.to } }"
            data-testid="back-btn"
          >
            <base-icon class="svg" :width="20" name="back">
              <icon-back :fill="`#27AE60`" />
            </base-icon>
          </router-link>
        </div>
        <div>
          <form :disabled="loading" class @submit.prevent="submit()">
            <div class="logo flex justify-center mb-4">
              <div class="w-16 h-16 mr-2"><logo-workkami /></div>
              <div class="logo-text">WORKKAMI</div>
            </div>

            <div v-if="error" class="mt-2 mb-2 color-error text-center">
              {{ error }}
            </div>
            <div class="mt-6">
              <base-input-text
                v-model="name"
                :disabled="loading"
                type="text"
                :placeholder="$t('login.signup.full')"
                name="name"
                data-testid="fullname-input"
                :class="{ error: invalidName }"
              />
            </div>
            <div class="mt-4">
              <base-input-text
                v-model="email"
                :disabled="loading"
                type="email"
                :placeholder="$t('login.email')"
                name="email"
                data-testid="email-input"
                :class="{ error: invalidEmail }"
              />
            </div>
            <div class="mt-4">
              <base-input-password
                v-model="password"
                :disabled="loading"
                type="password"
                :placeholder="$t('login.signup.password')"
                name="password"
                data-testid="password-input"
                :class="{ error: invalidPassword }"
              />
            </div>
            <div class="mt-4">
              <base-input-password
                v-model="confirmPassword"
                :disabled="loading"
                type="password"
                :placeholder="$t('login.signup.confrim')"
                name="confirmPassword"
                data-testid="confirm-password-input"
                :class="{ error: invalidConfirmPassword }"
              />
            </div>

            <div class="mt-8 mb-4">
              <base-button
                full
                color="brand"
                type="submit"
                data-testid="signup-btn"
              >
                {{ $t('login.signup.name') }}
              </base-button>
            </div>
            <div class="mt-4 text-sm text-gray-500 text-center">
              {{ $t('login.signup.have') }}?
              <router-link
                class="color-brand"
                :to="{ name: 'Landing', query: { to: $route.query.to } }"
                data-testid="signin-link"
              >
                {{ $t('login.signup.signin') }}
              </router-link>
            </div>
          </form>
        </div>
      </div>
      <div class="text-sm text-gray-500 text-center mt-4">
        {{ $t('login.accept') }}
        <router-link
          class="color-brand"
          :to="`/${$i18n.locale}/terms`"
          data-testid="terms-link"
        >
          {{ $t('login.conditions') }}
        </router-link>
        {{ $t('login.us') }}
      </div>
    </div>

    <div class="background-left">
      <background-auth-left />
    </div>
    <div class="background-right">
      <background-auth-right />
    </div>
  </div>
</template>

<script>
import BackgroundAuthLeft from '../components/icons/BackgroundAuthLeft';
import BackgroundAuthRight from '../components/icons/BackgroundAuthRight';
import IconBack from '@/components/icons/IconBack';
import LogoWorkkami from '../components/icons/LogoWorkkami';
import LoadingMixin from '@/mixins/LoadingMixin';
import { Providers } from '@/types/Auth';
import firebase from '@/services/firebase';
import localJwt from '@/services/local-jwt';

export default {
  components: {
    BackgroundAuthLeft,
    BackgroundAuthRight,
    IconBack,
    LogoWorkkami
  },
  mixins: [LoadingMixin],
  name: 'Signup',
  watch: {},
  data() {
    return {
      name: '',
      invalidName: false,
      email: '',
      invalidEmail: false,
      password: '',
      invalidPassword: false,
      confirmPassword: '',
      invalidConfirmPassword: false,
      error: ''
    };
  },
  beforeRouteEnter(to, _, next) {
    const routeNames = {
      boardID: 'Boards-Id',
      teamID: 'Teams-Board'
    };
    const { boardID, teamID } = to.query;
    const routeNameKey = Object.keys(to.query).find(e => e.includes('ID'));
    const routeID = boardID || teamID;
    const isLoggedIn = localJwt.hasAccessToken();
    const shouldRedirect = routeID && isLoggedIn;
    const toTarget = () => ({
      name: routeNames[routeNameKey],
      params: {
        id: routeID,
        locale: to.params.locale || 'en'
      }
    });
    if (shouldRedirect) next(toTarget());
    else next();
  },
  mounted() {
    this.$store.commit(
      'auth/SET_TO',
      this.$route.query.to ? this.$route.query.to : ''
    );
  },
  methods: {
    async submit() {
      this.error = '';
      this.invalidName = false;
      this.invalidEmail = false;
      this.invalidPassword = false;
      this.invalidConfirmPassword = false;

      if (!this.name) {
        this.error = `${this.$t('login.signup.error.name')}`;
        this.invalidName = true;
      } else if (!this.email) {
        this.error = `${this.$t('login.signup.error.email')}`;
        this.invalidEmail = true;
      } else if (!this.password) {
        this.error = `${this.$t('login.signup.error.password')}`;
        this.invalidPassword = true;
      } else if (this.password != this.confirmPassword) {
        this.error = `${this.$t('login.signup.error.notmatch')}`;
        this.invalidConfirmPassword = true;
      }
      if (this.error) return;

      this.onLoading();
      const { name, email, password } = this.$data;
      const registration = {
        name,
        email,
        password,
        registerType: Providers.EMAIL
      };
      let isFirebaseSuccess = false;
      try {
        await firebase.auth().signInAnonymously();
        isFirebaseSuccess = true;
        await this.$store.dispatch('auth/register', registration);

        const to = this.$store.getters['auth/getTo'];
        this.$router.push(to || { name: 'Boards-List' });
      } catch (error) {
        const defaultError = this.$t('fail.try_again', {
          do: this.$t('login.signup.name').toLowerCase()
        });
        if (isFirebaseSuccess) {
          await firebase.auth().signOut();
          isFirebaseSuccess = false;
        }
        this.error = error?.response?.data?.message || defaultError;
      }
      this.offLoading();
    }
  }
};
</script>

<style scoped></style>
